import * as React from "react"
import Layout from "../components/layout"
import Articles from "../components/articles"

const codeStyles = {
  textAlign: "center",
}
const bgstyle = {
	backgroundColor: "#222d3a",
}
const randomGenerator = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const randomPosition = randomGenerator(0, 4);

const imageRandom = "/img/404/"+randomPosition+".gif";


const NotFoundPage = () => {
  return (
  <>
  <Layout pageTitle="NotFoundPage">
        <section id="main" style={bgstyle}>
      <div id="worktitle">
        <div className="left-col">
          <h1 style={codeStyles}>Oops! That page can’t be found.</h1>
        </div>
        <div className="right-col picture" style={codeStyles}>
            <img src={imageRandom} width="840" alt="Not Found" />
        </div>
      </div>
    </section>
<Articles></Articles>
</Layout>
  </>

  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
